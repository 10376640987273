import React from "react";
import { Icon } from "astro-icon/components";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";

const MainNavigationMenu: React.FC = () => {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Shop</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-4 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              <li className="row-span-3">
                <NavigationMenuLink href="/shop">
                  <div className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md">
                    <Icon name="mdi:shop" class="h-6 w-6" />
                    <div className="mb-2 mt-4 text-lg font-medium">
                      Shop All
                    </div>
                    <p className="text-sm leading-tight text-muted-foreground">
                      Browse our full catalog of health products
                    </p>
                  </div>
                </NavigationMenuLink>
              </li>
              <li>
                <NavigationMenuLink href="/shop/supplements">
                  Supplements
                </NavigationMenuLink>
              </li>
              <li>
                <NavigationMenuLink href="/shop/foods">
                  Healthy Foods
                </NavigationMenuLink>
              </li>
              <li>
                <NavigationMenuLink href="/shop/skincare">
                  Skincare
                </NavigationMenuLink>
              </li>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Learn</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
              <li>
                <NavigationMenuLink href="/learn/articles">
                  Articles
                </NavigationMenuLink>
              </li>
              <li>
                <NavigationMenuLink href="/learn/videos">
                  Videos
                </NavigationMenuLink>
              </li>
              <li>
                <NavigationMenuLink href="/learn/podcast">
                  Podcast
                </NavigationMenuLink>
              </li>
              <li>
                <NavigationMenuLink href="/learn/recipes">
                  Healthy Recipes
                </NavigationMenuLink>
              </li>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink href="/subscribe">
            Subscribe & Save
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default MainNavigationMenu;
